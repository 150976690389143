<template>
  <table-parent
    :key="tableKey"
    :Data="tableData"
    :loadingData="loadingData"
    :disabledCols="disabledCols"
    :disableSubData="disableSubData"
    :style="tableStyle"
    @previousPage="setPage(-1)"
    @nextPage="setPage(1)"
    @changePage="changedRows"
    @BtnEvent="BtnEvent"
    @fullReload="reload(true)"
    @readFilteredData="readFilteredData"
  >
    <template #subitem="row">
      <slot name="subitem" v-bind="row"></slot>
    </template>
  </table-parent>
</template>

<script>
import TableComponent from "@/components/Table";
const safeApi = window["apiTimeout"]();

export default {
  props: {
    startup: {
      type: Boolean,
      default: true,
    },
    reloadCount: {
      type: Number,
      default: 0,
    },
    tableHead: {
      type: Array,
      required: true,
      default: () => [],
    },
    prepareFn: {
      type: Function,
      required: true,
      default: () => [],
    },
    prepareItemFn: {
      type: [Function, Boolean],
      required: false,
      default: false,
    },
    apiModule: {
      type: [Function, Object, Boolean],
      required: true,
      default: false,
    },
    apiModuleName: {
      type: String,
      default: "",
    },
    tableMinimizeHead: {
      type: Boolean,
      default: false,
    },
    tableSpread: {
      type: Boolean,
      default: false,
    },
    disableSubData: {
      type: Boolean,
      default: false,
    },
    chunk: {
      type: Number,
      default: 15,
    },
    prefetch: {
      type: Number,
      default: 2,
    },
    numberColumn: {
      type: Boolean,
      default: true,
    },
    noReload: {
      type: Boolean,
      default: false,
    },
    disabledCols: {
      type: Array,
      default: () => [],
    },
    apiModuleOptions: {
      type: Object,
      default: () => {},
    },
    tableStyle: {
      default: () => null
    }
  },
  components: {
    "table-parent": TableComponent,
  },
  data() {
    const chunk =
      parseInt(localStorage.getItem("tableShowPerPage")) || this.chunk;

    return {
      tableKey: 0,
      tableData: {
        options: {
          searchModule: this.apiModuleName,
          // onlyFilterCalls: true,
          spread: this.tableSpread,
          minimizeHead: this.tableMinimizeHead,
          show: chunk,
          ...this.apiModuleOptions,
        },
        head: [...this.tableHead],
        body: [],
      },
      viewRows: chunk,
      records: [],
      totalRecords: 0,
      filterQuery: "",
      page: 1,
      loadingData: false,
    };
  },
  watch: {
    viewRows() {
      this.reload();
    },
    totalRecords(x) {
      this.$emit("totalRecords", x);
    },
    reloadCount() {
      this.reload();
    },
    startup: {
      handler(x) {
        if (x && this.isActive) this.syncData(true);
      },
      immediate: true,
    },
    isActive: {
      handler(x) {
        if (this.isActive) {
          if (this.numberColumn && !this.tableData.head[0].__isAuto) {
            this.tableData.head.unshift({
              __isAuto: true,
              title: "NR.",
              minimize: 2,
              sort: true,
              fixed: {
                left: true,
              },
            });
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    setPage(add) {
      if (!Number.isInteger(add)) {
        this.page = 1;
        return;
      }

      safeApi(() => {
        this.page = Math.max(1, (this.page += add));

        this.$nextTick(this.syncData);
      });
    },
    saveCurrentFilter(query) {
      if (this.isString(query) && query.trim()) {
        this.filterQuery = query;
      } else this.filterQuery = "";
    },
    readFilteredData(data, callback, query) {
      if (Array.isArray(data?.data?.result)) {
        this.saveCurrentFilter(query);
        this.setTotalLength(data.data.recordsQuantity ?? data.data.qty);

        this.reload(false, true, true);

        this.$nextTick(() =>
          this.addData(this.prepareResponse(data.data.result))
        );
      } else {
        this.reload(true);
      }
    },
    reload(forced, noRefresh, noSetTotal) {
      if (forced) this.$emit("reload");

      if (!noSetTotal) this.setTotalLength(0);

      this.page = 1;
      this.filterQuery = "";
      this.tableData.body.splice(0);
      this.records.splice(0);
      this.tableData.options.refresh = true;

      if (this.noReload) return;

      if (forced) this.tableKey++;
      if (noRefresh) return;

      this.$nextTick(() => this.syncData(true));
    },
    changedRows(rowsPerPage) {
      if (Number.isInteger(rowsPerPage)) {
        this.viewRows = rowsPerPage;
      }
    },
    BtnEvent(type, index) {
      const findRecord = this.records[index];

      this.$emit(`Btn${type || ""}`, findRecord?.data, index);
    },
    getPage(page) {
      if (!Number.isInteger(page)) return;

      if (typeof this.apiModule === "function") {
        return this.apiModule(page, this.viewRows, this.filterQuery);
      }

      return null;
    },
    setTotalLength(val) {
      const x = +val;
      if (Number.isInteger(x)) {
        this.tableData.options.totalItems = this.totalRecords = val;
      }
    },
    prepareResponse(arr) {
      if (!Array.isArray(arr)) return [];

      return arr
        .filter((e) => this.records.findIndex((l) => l.id === e.id) === -1)
        .map((e) => {
          if (!this.isObject(e)) return false;

          if (this.prepareItemFn) {
            e = this.prepareItemFn(e);
          }

          return {
            id: e.id,
            row: this.prepareFn(e),
            data: e,
            _Data: e,
          };
        })
        .filter((e) => e);
    },
    responseReader(res) {
      if (Array.isArray(res?.data?.result)) {
        const totalItems = res.data.recordsQuantity ?? res.data.qty;
        if (Number.isInteger(totalItems)) {
          this.setTotalLength(totalItems);

          if (this.totalRecords === 0) {
            this.reload(false, true);
            this.tableData.body = [false];
            this.tableKey++;
          }
        }
        return res.data.result;
      } else {
        return null;
      }
    },
    addData(data, onlyRecords) {
      const rows = ((x) => (x.length ? x : [false]))(data.map((e) => e.row));
      const add = (arr, onlyPrepared) =>
        arr.splice(arr.length, 0, ...(onlyPrepared ? rows : data));

      const lastRecordsLength = this.recordsLength;

      add(this.records);

      if (onlyRecords) return;

      if (this.numberColumn) {
        data.forEach((e, i) =>
          e.row.unshift({
            val: lastRecordsLength + i + 1,
            border: "right",
          })
        );
      }

      add(this.tableData.body, true);

      if (data.length)
        this.tableData.body.forEach((e, i) =>
          e ? (e.NR = e.index = i) : null
        );
    },
    syncData(forced = false) {
      if (!this.needToSync && !forced) return;

      const promises = [];
      const sync = (page) => promises.push(this.getPage(page));
      if (forced) sync(1);
      else this.needPages.forEach((e) => sync(e));

      const syncError = (msg) => {
        this.$toastr.e({
          msg: msg || "Unele date pentru tabelă nu au fost încărcate.",
          preventDuplicates: true,
        });

        this.reload(false, true);
      };

      const newData = [];

      if (promises.length) {
        this.loadingData = true;
      }
      const allPromises = Promise.all(promises)
        .then((all) => {
          all.forEach((res, index) => {
            let prepared = [];
            const data = this.responseReader(res);

            if (!data) {
              syncError();
              const spaces = Math.min(
                this.totalRecords - (this.needPages[index] - 1) * this.viewRows,
                this.viewRows
              );
              const fill = {
                id: null,
                row: Array(this.columnsLength).fill("-"),
                data: null,
              };

              for (let i = 0; i < spaces; i++) {
                prepared.push(fill);
              }
            } else {
              prepared = this.prepareResponse(data);
            }

            newData.splice(newData.length, 0, ...prepared);
          });
        })
        .catch(syncError);

      Promise.allSettled([allPromises]).then(() => {
        if (newData.length) {
          this.addData(newData);
        }

        if (forced) {
          this.$nextTick(this.syncData);
        }

        this.loadingData = false;
      });
    },
  },
  computed: {
    isActive() {
      return !!this.apiModuleName;
    },
    loadedPages() {
      return Math.floor(this.recordsLength / this.viewRows);
    },
    recordsLength() {
      return this.records.length;
    },
    needToSync() {
      return this.totalRecords - this.recordsLength > 0;
    },
    needPages() {
      if (!this.needToSync) return [];

      const pages = [];
      const needItems =
        Math.min(
          this.totalRecords,
          (this.page + this.prefetch) * this.viewRows
        ) - this.recordsLength;
      for (let i = 0; i < Math.ceil(needItems / this.viewRows); i++) {
        pages.push(this.loadedPages + i + 1);
      }
      return pages;
    },
    columnsLength() {
      return this.tableHead.length + (this.numberColumn ? -1 : 0);
    },
    allColumnsLength() {
      return this.tableHead.length;
    },
  },
};
</script>
