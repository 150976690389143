<template>
  <div>
    <div class="form-group">
      <b>Obiectivului</b>
      <div class="mx-full_width">
        <v-select
          v-model="annualObjective.institutionObjective"
          label="name"
          :options="institutionObjectives"
          :searchable="true"
          :disabled="!!initial"
        >
        </v-select>
      </div>
    </div>

    <div class="form-group">
      <b>Anul obiectivului</b>
      <div class="mx-full_width">
        <date-picker
          v-model="annualObjective.year"
          type="year"
          valueType="YYYY"
          popup-class="bigger-index"
          :disabled-date="valueDisable"
          :disabled="!annualObjective.institutionObjective || !!initial"
        />
      </div>
    </div>
    <div class="form-group">
      <b>Data de început/sfârșit a obiectivului</b>
      <date-picker
        v-model="annualObjective.intervalObjective"
        type="date"
        valueType="date"
        popup-class="bigger-index"
        :disabled-date="valueDisableDate"
        :disabled="!annualObjective.year"
        :default-value="defaultDate"
        range
      />
    </div>

    <!--       <div style="flex: 1;" class="form-group">
        <b>Data de sfârșit a obiectivului</b>
        <date-picker
          v-model="annualObjective.endDate"
          type="date"
          valueType="date"
          popup-class="bigger-index"
          :disabled-date="valueDisableEndDate"
          :disabled="!annualObjective.startDate"

        />
      </div> -->
    <!--     <div
      v-if="!initial"
      @click="addObjectives"
      style="display: flex; justify-content: flex-end;"
    >
      <i
        v-tooltip="'Adaugă obiectivul'"
        class="fas fa-plus-circle"
        style="cursor: pointer;  color: #39f"
      ></i>
    </div>
    <div class="sic_list" style="margin-top: 1rem">
      <ol>
        <draggable v-model="selectedObjectives">
          <li v-for="item in selectedObjectives" :key="item.id">
            <div>
              <span style="text-align: left">{{
                item.institutionObjective.name
              }}</span>
              <div class="sic_list_actions">
                <div class="sic_list_remove" @click="deleteObjective(item)">
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </li>
        </draggable>
      </ol>
    </div> -->
  </div>
</template>

<script>
import { INSTITUTION_OBJECTIVES } from "../api.js";
import DatePicker from "@/mixins/DatePicker.js";

const defaultData = () => ({
  year: null,
  intervalObjective: null,
  institutionObjective: null,
});

export default {
  props: {
    initial: {
      type: Object,
      default: () => null,
    },
  },

  mixins: [DatePicker],

  components: { draggable: () => import("vuedraggable") },

  data() {
    return {
      apiModule: INSTITUTION_OBJECTIVES.get,
      annualObjective: this.initial
        ? {
            year: JSON.stringify(this.initial.year),
            institutionObjective: this.initial.institutionObjective,
            intervalObjective: [
              new Date(this.initial.startDate),
              new Date(this.initial.endDate),
            ],
          }
        : defaultData(),
      selectedObjectives: [],
      institutionObjectives: [],
    };
  },

  watch: {
    "annualObjective.year"() {
      this.annualObjective.intervalObjective = null;
    },
    annualObjective: {
      handler(val) {
        //if (this.initial) {
        const intervalObjective = val.intervalObjective;
        this.$emit("input", {
          year: Number(val.year),
          institutionObjective: val.institutionObjective,
          startDate: intervalObjective ? intervalObjective[0] : null,
          endDate: intervalObjective ? intervalObjective[1] : null,
        });
        // }
      },
      deep: true,
      immediate: true,
    },
    deep: true,
    immediate: true,
  },

  computed: {
    checkAllFiledComplete() {
      for (const key in this.annualObjective) {
        if (!this.annualObjective[key]) return false;
      }
      return true;
    },
    defaultDate() {
      if (new Date().getFullYear() != this.annualObjective.year) {
        return new Date("1-1-" + this.annualObjective.year);
      } else {
        return new Date();
      }
    },
  },

  methods: {
    valueDisable(year) {
      const currentDate = new Date();
      const currentUTCYear = currentDate.getUTCFullYear();

      const minDatePickerDate = currentDate.setYear(currentUTCYear - 1);
      const maxDatePickerDate = new Date().setYear(currentUTCYear + 15);

      if (minDatePickerDate > year || maxDatePickerDate < year) {
        return true;
      }

      return false;
    },

    valueDisableDate(date) {
      const currentDate = new Date(this.defaultDate);
      const currentDay = currentDate.getDate();
      const minDatePickerDate = currentDate.setDate(currentDay - 1);
      const endYear = Number(this.annualObjective.year) + 1;
      const maxDatePickerDate = new Date("1-1-" + endYear);

      if (minDatePickerDate >= date || maxDatePickerDate <= date) {
        return true;
      }

      return false;
    },

    /*  valueDisableEndDate(date) {
      const currentDate = new Date(this.annualObjective.startDate);
      const currentUTCYear = currentDate.getUTCFullYear();
      const currentUTCDate = currentDate.getUTCDate();

      const minDatePickerDate = currentDate.setDate(currentUTCDate + 1);
      const maxDatePickerDate = new Date().setYear(currentUTCYear);

      if (minDatePickerDate >= date || maxDatePickerDate <= date) {
        return true;
      }

      return false;
    },
 */
    deleteObjective(option) {
      if (this.initial) return;
      this.selectedObjectives = this.selectedObjectives.filter(
        (e) => e.id !== option.id
      );
    },
    async checkIfObjectiveAdded() {
      /*       const find = this.selectedObjectives.find(
        (el) =>
          el.institutionObjective.id ==
            this.annualObjective.institutionObjective.id &&
          this.annualObjective.year == el.year
      );

      if (find) {
        this.$toastr.w("Obiectiv deja adaugat.");
        return false;
      } */
      /*       return await ANNUAL_INSTITUTION_OBJECTIVES.checkIfExist(
        this.annualObjective.institutionObjective.id,
        this.annualObjective.year
      )
        .then((res) => {
          if (res.data.result) {
            this.$toastr.w("Obiectiv deja adaugat.");
            return false;
          }

          return true;
        })
        .catch((err) => {}); */
    },
    async addObjectives() {
      if (this.initial) return;

      if (this.checkAllFiledComplete) {
        if (!(await this.checkIfObjectiveAdded())) return;

        const intervalObjective = this.annualObjective.intervalObjective;

        this.selectedObjectives.push(
          this.deepClone({
            year: this.annualObjective.year,
            institutionObjective: this.annualObjective.institutionObjective,
            startDate: intervalObjective[0],
            endDate: intervalObjective[1],
          })
        );

        this.annualObjective = defaultData();

        this.$emit("input", this.selectedObjectives);
      } else {
        this.$toastr.w("Complează toate câmpurile.");
      }
    },
  },
  created() {
    INSTITUTION_OBJECTIVES.get(1, 2000, "isActive=1").then((res) => {
      this.institutionObjectives = res.data.result;
    });
  },
};
</script>

<style></style>
